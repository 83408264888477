<template>
  <div>
    <v-card>
      <v-card-title>Book now:</v-card-title>
      <v-card-text>
        <v-card color='accent'>
          <v-card-title>{{activity.description}}</v-card-title>
          <v-card-text>
          <v-row>
            <v-col md=2>
              <v-card>
                <v-card-title class='justify-center'>Number of persons</v-card-title>
                <v-card-text class='text-h2 text-center'>
                  {{formData.pax.pax}}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md=2>
              <v-card>
                <v-card-title class='justify-center'>Price per person</v-card-title>
                <v-card-text class='text-h2 text-center'>
                  ${{formData.pax.price}}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          </v-card-text>
        </v-card>
        <v-form ref='form1'>
          <v-select return-object :items="items" v-model="formData.pax" item-text="pax" item-value="pax" label="Number of persons"></v-select>
          <v-text-field v-model="formData.name" label="Name (required)" :rules="[stdRules.required]"></v-text-field>
          <v-text-field v-model="formData.email" label="Email address (required)" :rules="[stdRules.required]"></v-text-field>
          <v-text-field v-model="formData.mobile" label="Mobile number (required)" :rules="[stdRules.required]"></v-text-field>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formData.date"
            label="Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="formData.date"
          no-title
          scrollable
          :min="minDate"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(formData.date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>          
          <v-select label="Time" :items="timeSlots" :rules="[stdRules.required]" v-model="formData.time"></v-select>
          <v-textarea v-model="formData.message" label="Message"></v-textarea>
        </v-form>
      <vue-recaptcha
        sitekey="6Le5jN0ZAAAAALClYDGiK2RiMB0lGx448klPXnLq"
        ref="invisibleRecaptcha"
        @verify="onVerify"
        @expired="onExpired"
        size="invisible"
        data-badge="inline"
      ></vue-recaptcha>        
      </v-card-text>
      <v-card-actions>
        <v-btn @click='processForm()' color="primary">
          Send
          <v-icon>mdi-mail</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
      <v-dialog v-model="showDialog" width="50%">
        <v-card>
          <v-card-title>Thank you for your message</v-card-title>
          <v-card-text>We will respond as soon as possible!</v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="loading" fullscreen>
        <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
          <v-layout justify-center align-center>
            <v-progress-circular
              indeterminate
              color="primary">
            </v-progress-circular>
          </v-layout>
        </v-container>
      </v-dialog>
      <v-dialog v-model="errorDialog" width="50%">
        <v-card>
          <v-card-title class='red lighten-1'>
            <v-icon
                large
                left
              >
                mdi-alert
              </v-icon>
            Error</v-card-title>
          <v-card-text>{{error}}<br />
Please try again later or use one of the other communication options
<FooterIcons/>
          </v-card-text>
        </v-card>
      </v-dialog>       
  </div>
</template>


<script>
import GlobalHelper from "@/mixins/GlobalHelper"
import FooterIcons from "@/components/FooterIcons"
import VueRecaptcha from 'vue-recaptcha'

export default {
  mixins:[GlobalHelper],
  components:{
    FooterIcons,VueRecaptcha
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,    
    timeSlots:['08:00','11:00'],
    formData:{
      pax:0
    },    
    showDialog:false,  
    errorDialog:false,
    error:"",
    loading:false,
  }),
  computed:{
    minDate(){
      var myDate = new Date();
      //add a day to the date
      myDate.setDate(myDate.getDate() -1);
      return myDate.toISOString().substr(0, 10)
    },
    activity(){
      return this.$route.params.activity == null? "Wingfoiling Lesson" : this.activities[this.$route.params.activity]
    },
    items(){
      if(!this.$route.params.activity){
        return[]
      }
      let i = this.activities[this.$route.params.activity].products;
      return i.reverse()
    },
    price(){
      if(!this.$route.params.activity){
        return 0
      }
      return this.activities[this.$route.params.activity].products.price;
    }
  },
  methods:{
    processForm(){
      if(!this.$refs.form1.validate()){
        //this.errorDialog = true;
        return;
      }
      this.loading = true;
      this.$refs.invisibleRecaptcha.reset()
      this.$refs.invisibleRecaptcha.execute()
    },
    onVerify: function (response) {
      this.sendForm(response)
    },
    onExpired: function () {
      this.loading = false;
      //this.$refs.invisibleRecaptcha.reset()
    },
    resetRecaptcha () {
      this.$refs.invisibleRecaptcha.reset() // Direct call reset method
    },   
    sendForm(recaptcha){
      let data = this.formData;
      data.pax = data.pax.pax;
      data.activity = this.activity.description;
      data.message = data.message? data.message : " ";
      let target="FOIL"
      this.sendToServer({action:'contactform',...data,recaptcha,target}).then(()=>{
        //this.$refs.form1.reset()
        this.formData = {
          pax:this.activities[
                    this.$route.params.activity
              ].products[this.activities[this.$route.params.activity].products.length-1]
        };
        this.$refs.form1.resetValidation()
        this.showDialog = true
      }).catch((e)=>{
       this.error = e
       this.errorDialog = true
      }).finally(()=>{
        this.loading=false
      })
    }       
  },
  mounted(){
    if(!this.$route.params.activity){
      this.$router.push("/")
      return
    }
    this.$set(this.formData,"pax",this.activities[this.$route.params.activity].products[this.activities[this.$route.params.activity].products.length-1])
  },
  watch:{
    showDialog(){
      if(this.showDialog == false){ 
        this.$router.push("/")
      }
    }
  }
}
</script>
